import axios from "axios";
import { HOST_URL } from "./host";
export const SET_INVOICES = "SET_INVOICES";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setInvoices(invoices) {
  return {
    type: SET_INVOICES,
    payload: invoices,
  };
}
export async function handleGetInvoice({ token, id }, callback) {
  return axios
    .get(`${HOST_URL}/api/user/invoice/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.data.success) {
        callback(res.data.data);
      } else {
        callback({ type: "danger", message: res.data.message });
      }
    })
    .catch((err) => console.error(err));
}

export function handleGetInvoices({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/invoices`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setInvoices(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleNewInvoice({ token, data }, callback) {
  return async () => {
    return axios
      .post(
        `${HOST_URL}/api/user/invoice`,
        {
          data: data.data,
          currency: data.currency,
          wallet: data.wallet,
          name: data.name,
          tax_value: data.tax,
          taxable: data.taxable,
          type: data.type,
          note: data.note,
          discount: data.discount,
          language: data.language,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            handleGetInvoices({ token });
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export async function handleInvoiceResend(
  { token, id, action, amount, currency },
  callback
) {
  return axios
    .get(
      `${HOST_URL}/api/user/card/action/${id}?action=${action}&amount=${amount}&currency=${currency}`,
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.data.success) {
        callback(res.data.data);
      }
    })
    .catch((err) => console.error(err));
}

export function handleConvertPrice({ amount, from, to }) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${HOST_URL}/api/convert?amount=${amount}&from=${from}&to=${to}`)
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          const convertedAmount = res.data.data[to];
          const roundedAmount = parseFloat(convertedAmount).toFixed(2);
          resolve(roundedAmount);
        } else {
          reject("La conversion de devise a échoué.");
        }
      })
      .catch((err) => {
        console.error(err);
        reject("Une erreur s'est produite lors de la conversion de devise.");
      });
  });
}


