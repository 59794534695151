import axios from "axios";
import { HOST_URL } from "./host";
export const SET_PARTNERS = "SET_PARTNERS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setPartners(partners) {
  return {
    type: SET_PARTNERS,
    payload: partners,
  };
}
export function handleGetPartner({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/partner/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetPartners({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/partners`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setPartners(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export async function handleNewPartner(
  { token, name, email, contact, pay_amount, currency, type, cycle },
  callback
) {
  return axios
    .post(
      `${HOST_URL}/api/user/partner`,
      {
        name,
        email,
        contact,
        pay_amount,
        currency,
        type,
        cycle,
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          handleGetPartners(token);
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    })
    .catch((err) => console.error(err));
}

export function handleUpdatePartner(
  { token, id, name, email, contact, pay_amount, currency, type, cycle },
  callback
) {
  return async () => {
    return axios
      .put(
        `${HOST_URL}/api/user/partner/${id}`,
        {
          name,
          email,
          contact,
          pay_amount,
          currency,
          type,
          cycle,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDeletePartner({ token, id }, callback) {
  return async () => {
    return axios
      .delete(`${HOST_URL}/api/user/partner/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}
