import axios from 'axios';
import { HOST_URL } from './host';
export const RETRIEVE_CREDITS = 'RETRIEVE_CREDITS';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
};
function retrieveCredits(credits){
    return {
        type: RETRIEVE_CREDITS,
        payload: credits,
    };
}

export function handleRetrieveCredits({token, filter})
{
    return async (dispatch) => {
        
        return axios
    .get(`${HOST_URL}/api/user/credits?type=${filter}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                dispatch(retrieveCredits(res.data.data))
            }
    })
    .catch(err=>console.error(err))
    } 

}

export async function handleGetCreditDetails({token, id}, callback)
{
        
        return axios
    .get(`${HOST_URL}/api/user/credit/${id}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                callback(res.data.data);
            }
    })
    .catch(err=>console.error(err));
}

export async function handleGetCreditConfig({token, amount, times, cycle}, callback)
{
    return axios
    .get(`${HOST_URL}/api/user/credit/get-config?amount=${amount}&times=${times}&cycle=${cycle}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){
                callback(res.data.data);
            }
    })
    .catch(err=>console.error(err));
}

export async function handleNewCredit({token, amount, description, times, cycle},  callback)
{
    
    return axios
    .post(`${HOST_URL}/api/user/credits`,{
        amount,
        cashback_type : times,
        cashback_cycle : cycle,
        description
    },{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((res)=>{
            if(res.data.success){ 
                callback({type: 'success', message: res.data.message});
            }else{
                callback({type: 'danger', message: res.data.message})
            }
    })
    .catch(err=>console.error(err));
}