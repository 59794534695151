import {
    RETRIEVE_CREDITS,
} from '../actions/credits';

const initialState = []
export default function credits(state=initialState, action){
    switch (action.type) {
        case RETRIEVE_CREDITS:
            return action.payload;
        default:
            return state;
    }
}