import {
    RETRIEVE_QRCODES,
} from '../actions/qrcodes';

const initialState = []
export default function qrcodes(state=initialState, action){
    switch (action.type) {
        case RETRIEVE_QRCODES:
            return action.payload;
        default:
            return state;
    }
}