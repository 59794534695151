import {
    SET_CUSTOMERS
} from '../actions/customers';

export default function customers(state=[], action){
    switch (action.type) {
        case SET_CUSTOMERS:
            return action.payload;
        default:
            return state;
    }
}