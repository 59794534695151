
import { 
    SET_AUTHED_USER,
    REMOVE_AUTHED_USER,
    UPDATE_AUTHED_USER,
    SET_COMPANY,
    SET_PROFILE,
    SET_AVATAR,
    SET_LOGO,
    SET_USER_SETTING,
    SET_USER_SERVICES,

 } from "redux/actions/authedUser";

const initialState = {
    isAuthenticated: false,
    balance: 0,
    currency: '',
    rewards: 0,
    username: '',
    score: 0
}

export default function authedUser(state=initialState, action){
    switch (action.type) {
        case SET_AUTHED_USER:
            return {
                ...action.payload,
                isAuthenticated: true
            }
        case REMOVE_AUTHED_USER:
            return {isAuthenticated: false}
        case UPDATE_AUTHED_USER:
            return {
                ...state,
                ...action.payload
            }
        case SET_COMPANY:
            return {
                ...state,
                company: action.payload
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            }
        case SET_AVATAR:
            return{
                ...state,
                avatar: action.payload
            }
            case SET_LOGO:
            return{
                ...state,
                logo: action.payload
            }
        case SET_USER_SETTING:
            return{
                ...state,
                setting: action.payload
            }
        case SET_USER_SERVICES:
            return{
                ...state,
                services: action.payload
            }
        default:
            return state;
    }
}