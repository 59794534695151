import axios from 'axios';
import { HOST_URL } from './host';
export const SET_REFERALS = 'SET_REFERALS';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
}
function setReferals(referals){
    return {
        type: SET_REFERALS,
        payload: referals === null? []: referals
    }
}
export function handleGetReferals({token}){
    return async (dispatch)=> {
        
       return axios
    .get(`${HOST_URL}/api/user/list-referral`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`
        },
    })
    .then((res)=>{
        if(res.status===200){
            if(res.data.success){
                dispatch(setReferals(res.data.data))
            }
        }
    })
    .catch((err)=>console.error(err))
    } 
}