import axios from "axios";
import { HOST_URL } from "./host";
import { format } from "date-fns";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setCampaigns(campaigns) {
  return {
    type: SET_CAMPAIGNS,
    payload: campaigns,
  };
}
export function handleGetCampaign({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/campaign/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetCampaigns({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/campaigns`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCampaigns(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleUpdateCampaign(
  { token, id, name, message, type, template, start_date, channel },
  callback
) {
  return async () => {
    const formattedStartDate = format(new Date(start_date), "yyyy-MM-dd'T'HH:mm:ssxxx");
    return axios
      .put(
        `${HOST_URL}/api/user/marketingCampaigns/${id}`,
        {
          name,
          message,
          type,
          template,
          start_date:formattedStartDate,
          for: channel,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleNewCampaign({ token, name, message, type, template, start_date, channel }, callback) {
  return async () => {
    const formattedStartDate = format(new Date(start_date), "yyyy-MM-dd'T'HH:mm:ssxxx");
    return axios
      .post(
        `${HOST_URL}/api/user/campaigns`,
        {
          name,
          message,
          type,
          template,
          start_date:formattedStartDate,
          for: channel,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            handleGetCampaigns(token);
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDeleteCampaign({ token, id }, callback) {
  return async () => {
    return axios
      .delete(`${HOST_URL}/api/user/Campaign/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}
