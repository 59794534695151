import axios from 'axios';
import { HOST_URL } from './host';
export const RETRIEVE_WHITELIST = 'RETRIEVE_WHITELIST';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
};
function retrieveWhitelist(whitelist){
    return {
        type: RETRIEVE_WHITELIST,
        payload: whitelist,
    };
}

export function handleRetrieveWhitelist({token})
{
    return async (dispatch) => {
        
        return axios
    .get(`${HOST_URL}/api/user/whitelist`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                dispatch(retrieveWhitelist(res.data.data))
            }
    })
    .catch(err=>console.error(err))
    } 

}

export async function handleIp({token, ip, mode}, callback)
{
        
        return axios
    .get(`${HOST_URL}/api/user/whitelist?mode=${mode}&ip=${ip}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){
                callback({type: 'success', message: res.data.message})
            }else{
                callback({type: 'danger', message: res.data.message})
            }
    })
    .catch(err=>console.error(err));

}



