
import { object } from "yup";
import { SET_CARDS, SET_USER_CARD, SET_USERS_CARD } from "../actions/virtualCards"; 
 const initialstate={userCard:{},usersCard:[], cards:[]}
export default function virtualCards(state = initialstate, action) { 
  switch (action.type) { 
    case SET_USER_CARD: 
      return { ...state, userCard: action.payload }; 
    case SET_USERS_CARD: 
      return { ...state, usersCard: action.payload }; 
    case SET_CARDS: 
      return { ...state, cards: action.payload }; 
    default: 
      return state; 
  } 
}