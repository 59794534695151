import {
    SET_INVOICES
} from '../actions/invoices';

export default function invoices(state=[], action){
    switch (action.type) {
        case SET_INVOICES:
            return action.payload;
        default:
            return state;
    }
}