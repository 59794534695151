import axios from "axios";
import { HOST_URL } from "./host";
export const SET_ISSUES = "SET_ISSUES";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setIssues(issues) {
  return {
    type: SET_ISSUES,
    payload: issues,
  };
}

export function handleNewIssue(
  { token, description, reference, amount, currency, imageissue },
  callback
) {
  const formData = new FormData();
  formData.append("imageissue", imageissue);
  formData.append("description", description);
  formData.append("reference", reference);
  formData.append("amount", amount);
  formData.append("currency", currency);
  return async () => {
    return axios
      .post(`${HOST_URL}/api/user/issue`, formData, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetIssues({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/issues`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setIssues(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}
