import React, { useState, useEffect, useContext} from 'react';

const ContextOnlineStatus = React.createContext(true);

export const OnlineStatusProvider = ({children}) =>{
    const [onlineStatus, setOnlineStatus] = useState(true);
    useEffect(()=>{
        window.addEventListener('online', function (){
            setOnlineStatus(true);
        });
        window.addEventListener('offline', function (){
            setOnlineStatus(false);
        })
        return ()=>{
            window.removeEventListener('online', function (){
                setOnlineStatus(true);
            });
            window.removeEventListener('offline', function (){
                setOnlineStatus(false);
            })
        }
    },[])

    return (
        <ContextOnlineStatus.Provider value={onlineStatus}>
            {children}
        </ContextOnlineStatus.Provider>
    )
}

export const useOnlineStatus = ()=>{
    const store = useContext(ContextOnlineStatus);
    return store;
}