import {
    SET_WALLETS
} from '../actions/wallets';

export default function wallets(state=[], action){
    switch (action.type) {
        case SET_WALLETS:
            return action.payload;
        default:
            return state;
    }
}