import axios from "axios";
import { HOST_URL } from "./host";
export const SET_COUPONS = "SET_COUPONS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setCoupons(coupons) {
  return {
    type: SET_COUPONS,
    payload: coupons,
  };
}
export function handleGetCoupon({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/coupon/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetCoupons({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/coupons`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCoupons(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleNewCoupon({ token, value, type, currency, times }, callback) {
  return async () => {
    return axios
      .post(
        `${HOST_URL}/api/user/coupon`,
        {
          value,
          type,
          currency,
          times,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            handleGetCoupons({ token });
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleUpdateCoupon({ token, id, value, type, currency, times }, callback) {
  return async () => {
    return axios
    .put(`${HOST_URL}/api/user/coupon/${id}`,
        {
          value,
          type,
          currency,
          times,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDeleteCoupon({ token, id }, callback) {
  return async () => {
    return axios
      .delete(`${HOST_URL}/api/user/coupon/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}


