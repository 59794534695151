import {
    RETRIEVE_SUB_ACCOUNTS,
} from '../actions/subAccounts';

const initialState = []
export default function subAccounts(state=initialState, action){
    switch (action.type) {
        case RETRIEVE_SUB_ACCOUNTS:
            return action.payload;
        default:
            return state;
    }
}