import { getTimeDifference } from "../../utils/utils";
import { removeAuthedUser } from "../actions/authedUser";
const expired = (store) => (next) => (action) => {
    const {getState} = store
    const {authedUser} = getState()
    
    const isTokenExpired = (getTimeDifference(new Date(), authedUser.expiredAt) <= 15)
        if(isTokenExpired){
            return next(removeAuthedUser())
        }
    return next(action)
  }
  
  export default expired 