import axios from "axios";
import { HOST_URL } from "./host";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setCustomers(customers) {
  return {
    type: SET_CUSTOMERS,
    payload: customers,
  };
}
export function handleGetCustomer({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/customer/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetCustomers({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/customers`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCustomers(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export async function handleNewCustomer(
  { token, name, email, contact, address, country, city, manager },
  callback
) {
  return axios
    .post(
      `${HOST_URL}/api/user/customer`,
      {
        name,
        email,
        contact,
        address,
        country,
        city,
        manager,
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          handleGetCustomers(token);
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    })
    .catch((err) => console.error(err));
}

export function handleUpdateCustomer(
  { token, id, name, email, contact, address, country, city, manager },
  callback
) {
  return async () => {
    return axios
      .put(
        `${HOST_URL}/api/user/customer/${id}`,
        {
          name,
          email,
          contact,
          address,
          country,
          city,
          manager,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDeleteCustomer({ token, id }, callback) {
  return async () => {
    return axios
      .delete(`${HOST_URL}/api/user/customer/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}
