import {
    SET_TRANSACTIONS
} from '../actions/transactions';

export default function transactions(state=[], action){
    switch (action.type) {
        case SET_TRANSACTIONS:
            return action.payload;
        default:
            return state;
    }
}