import {
    SET_CURRENCIES
} from '../actions/operation';

export default function currencies(state=[], action){
    switch (action.type) {
        case SET_CURRENCIES:
            return action.payload;
        default:
            return state;
    }
}