import axios from "axios";
import { updateAuthedUser } from "./authedUser";
import { HOST_URL } from "./host";
export const SET_CURRENCIES = "SET_CURRENCIES";

function setCurrencies(currencies) {
  return {
    type: SET_CURRENCIES,
    payload: currencies,
  };
}
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

export function handleTransfertFunds(
  { token, receiver, amount, description, wallet },
  callback
) {
  return async (dispatch) => {
    return axios
      .post(
        `${HOST_URL}/api/user/transfert`,
        {
          amount,
          description,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            operation: "5",
            receiver: receiver,
            wallet: wallet,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              balance: res.data.data.account.balance,
              rewards: res.data.data.account.rewards,
            };
            dispatch(updateAuthedUser(data));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
        console.error(err);
      });
  };
}

export function handleWithdrawRewards({ token, amount }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/withdraw-rewards?amount=${amount}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              balance: res.data.data.account.balance,
              rewards: res.data.data.account.rewards,
            };
            dispatch(updateAuthedUser(data));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleChanges(
  {
    token,
    inAmount,
    inWallet,
    inService,
    outService,
    outWallet,
    otp,
    operation,
  },
  callback
) {
  return async (dispatch) => {
    return axios
      .post(
        `${HOST_URL}/api/user/changes`,
        {
          in_amount: inAmount,
          in_wallet: outWallet,
          out_wallet: inWallet,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            operation: `${operation}`,
            inService: `${outService}`,
            outService: `${inService}`,
            otp: `${otp}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              balance: res.data.data.account.balance,
              rewards: res.data.data.account.rewards,
              accounts: res.data.data.account.wallets,
            };
            dispatch(updateAuthedUser(data));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
        console.error(err);
      });
  };
}

export function handleDepositMoney(
  { token, wallet, amount, currency, otp, service },
  callback
) {
  return async (dispatch) => {
    return axios
      .post(
        `${HOST_URL}/api/user/proceeds`,
        {
          amount,
          wallet,
          currency,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            operation: "1",
            service: `${service}`,
            otp: `${otp}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            if ([11, 3, 10].includes(service)) {
              callback(res.data.data);
            } else {
              const data = {
                balance: res.data.data.account.balance,
                rewards: res.data.data.account.rewards,
              };
              dispatch(updateAuthedUser(data));
              callback({ type: "success", message: res.data.message });
            }
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
      });
  };
}

export function handleWithdrawMoney(
  {
    token,
    wallet,
    amount,
    description,
    currency,
    service,
    accountName,
    bankName,
  },
  callback
) {
  return async (dispatch) => {
    return axios
      .post(
        `${HOST_URL}/api/user/proceeds?from=local`,
        {
          amount,
          wallet,
          currency,
          account_name: accountName,
          bank_name: bankName,
          description,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            operation: "4",
            service: `${service}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback(
              { type: "success", message: res.data.message },
              res.data.ref
            );
          } else {
            callback({ type: "danger", message: res.data.message }, null);
          }
        } else {
          callback(
            { type: "danger", message: "error occur during transaction" },
            null
          );
        }
      })
      .catch((err) => {
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
        console.error(err);
      });
  };
}

export function handleWithdrawConfirm({ token, ref, otp }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/proceeds/confirm/${ref}/${otp}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              balance: res.data.data.account.balance,
              rewards: res.data.data.account.rewards,
            };
            dispatch(updateAuthedUser(data));
            callback({ type: "success", message: res.data.message }, true);
          } else {
            callback({ type: "danger", message: res.data.message }, false);
          }
        }
      })
      .catch((err) => {
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
        console.error(err);
      });
  };
}

export function handleGetCurrencies() {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/currency-list`, {
        headers: {
          ...headers,
          //'Authorization': `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch(setCurrencies(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleConvert({ amount, from, to }, callback) {
  return axios
    .get(`${HOST_URL}/api/convert?amount=${amount}&from=${from}&to=${to}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) callback(res.data.data[to]);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export function handleGetOutAmount(
  { amount, from, to, token, type },
  callback
) {
  return axios
    .get(
      `${HOST_URL}/api/user/get-out-value?amount=${amount}&from=${from}&to=${to}&type=${type}`,
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success)
          callback(`${res.data.data.amount} ${res.data.data.devise}`);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export function handleGetFees({ amount, service, token }, callback) {
  return axios
    .get(
      `${HOST_URL}/api/user/get-transaction-fees?amount=${amount}&service=${service}`,
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success)
          callback(`${res.data.data.amount} ${res.data.data.currency}`);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export function handleGetServices(type, callback) {
  return axios
    .get(`${HOST_URL}/api/services-list?type=${type}`)
    .then((res) => {
      if (res.data.success) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export function handleGetTradeServices(callback) {
  return axios
    .get(`${HOST_URL}/api/services-list?trade=true`)
    .then((res) => {
      if (res.data.success) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export function collectPayment(
  {
    token,
    payer,
    payer_email,
    wallet,
    amount,
    currency,
    otp,
    apiKey,
    service,
    order_id,
  },
  callback
) {
  return async () => {
    return axios
      .post(
        `${HOST_URL}/api/agent/bills`,
        {
          payer,
          payer_email,
          amount,
          wallet,
          currency,
          order_id,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            "x-api-key": `${apiKey}`,
            operation: "2",
            service: `${service}`,
            otp: `${otp}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
      });
  };
}

export function getCodePayment({ token }, callback) {
  return async () => {
    try {
      const res = await axios.get(`${HOST_URL}/api/user/request-payment-otp`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        if (res.data.success) {
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      } else {
        callback({ type: "danger", message: res.data.message });
      }
    } catch (err) {
      console.error(err);
      callback({
        type: "danger",
        message: "Network issue, please try again!!!",
      });
    }
  };
}

export function emitPayment(
  { token, receiver, code, amount, currency, description },
  callback
) {
  return async () => {
    return axios
      .post(
        `${HOST_URL}/api/user/send/payment?code=${code}&uid=${receiver}&type=SINGLE`,
        {
          amount,
          currency,
          description,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
      });
  };
}

export function massPayment(
  { token, partnerType, partnerAmount, code, amount, currency, description },
  callback
) {
  return async () => {
    return axios
      .post(
        `${HOST_URL}/api/user/send/payment?code=${code}&partnerType=${partnerType}&partnerAmount=${partnerAmount}&type=BULK`,
        {
          amount,
          currency,
          description,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        callback({
          type: "danger",
          message: "network issue, please try again!!!",
        });
      });
  };
}
