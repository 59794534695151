import axios from "axios";
import { HOST_URL } from "./host";
export const SET_PRODUCTS = "SET_PRODUCTS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setProducts(products) {
  return {
    type: SET_PRODUCTS,
    payload: products,
  };
}
export function handleGetProduct({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/product/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetProducts({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/products`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setProducts(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleUpdateProduct(
  { token, id, name, description, cat, discount, price, currency, about, uploadable },
  callback
) {
  return async () => {
    return axios
      .put(
        `${HOST_URL}/api/user/product/${id}`,
        {
          name,
          description,
          cat,
          discount,
          currency,
          about,
          price,
          uploadable
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export async function handleNewProduct(
  { token, name, description, price, cat, data, currency, about },
  callback
) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("cat", cat);
    formData.append("about", about);
    formData.append("currency", currency);
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    resolve(formData);
  })
    .then((formData) => {
      return axios.post(`${HOST_URL}/api/user/product`, formData, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          handleGetProducts(token);
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    })
    .catch((err) => console.error(err));
}

export function handleStatusProduct({ token, id, active }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/product/${id}?active=${active}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDeleteProduct({ token, id }, callback) {
  return async () => {
    return axios
      .delete(`${HOST_URL}/api/user/product/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}
