import axios from "axios";
import { HOST_URL } from "./host";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_PACKAGES = "SET_PACKAGES";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setSubscription(current) {
  return {
    type: SET_SUBSCRIPTION,
    payload: current,
  };
}

function setPackages(packages) {
  return {
    type: SET_PACKAGES,
    payload: packages,
  };
}
export function handleGetPackages({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/packages`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch(setPackages(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleNewSubscription({ token, id, autoRenewal }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/subscriptions/new/${id}?auto=${autoRenewal}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      });
  };
}

export function handleCurrentSubscription({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/subscriptions/current`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            dispatch(setSubscription(res.data.data));
            //callback(res.data);
          } else{
            dispatch(setSubscription(null))
          }
        }
      });
  };
}

export function handleRenewalSubscription(
  { token, id, auto_renewal },
  callback
) {
  return async () => {
    return axios
      .put(
        `${HOST_URL}/api/user/subscriptions/${id}`,
        {
          auto_renewal,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          callback({ type: "success", message: res.data.message });
        }
      })
      .catch((err) => console.error(err));
  };
}
