import {
    SET_REFERALS
} from '../actions/referals';

export default function referals(state=[], action){
    switch (action.type) {
        case SET_REFERALS:
            return action.payload;
        default:
            return state;
    }
}