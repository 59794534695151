import axios from 'axios';
import { HOST_URL } from './host';
export const RETRIEVE_QRCODES = 'RETRIEVE_QRCODES';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
};
function retrieveQrcodes(qrcodes){
    return {
        type: RETRIEVE_QRCODES,
        payload: qrcodes,
    };
}

export function handleRetrieveQrcodes({token, filter})
{
    return async (dispatch) => {
        
        return axios
    .get(`${HOST_URL}/api/user/qr?for=${filter}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                dispatch(retrieveQrcodes(res.data.data))
            }
    })
    .catch(err=>console.error(err))
    } 

}

export async function handleGetQrcodeDetails({token, ref}, callback)
{
        
        return axios
    .get(`${HOST_URL}/api/user/qr?ref=${ref}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                callback(res.data.data);
            }
    })
    .catch(err=>console.error(err));

}

