import axios from 'axios';
import { HOST_URL } from './host';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
}
function setNotifications(notifications){
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications
    }
}
function readNotification(notificationId){
    return {
        type: READ_NOTIFICATION,
        payload: notificationId
    }
}
export function handleGetNotifications({token}){
    return async (dispatch) =>{
        
        return axios
        .get(`${HOST_URL}/api/user/messages?type=NOTICE`,{
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        })
        .then((res)=>{
            //console.log(res)
            if(res.status === 200){
                dispatch(setNotifications(res.data))
            }      
        })
        .catch((err)=>console.log(err))
    } 
}

export function handleGetNotification({token, notificationId}, callback){
    return async (dispatch)=>{
        
        return axios
    .get(`${HOST_URL}/api/user/message/${notificationId}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        //console.log(res)
        if(res.status === 200){
        //    if(res.data.success){
                dispatch(readNotification(res.data.id))
                callback(res.data)
            }
        //}
    })
    .catch((err)=>console.log(err))
    } 
}