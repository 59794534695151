import {
    SET_ISSUES
} from '../actions/issues';

export default function issues(state=[], action){
    switch (action.type) {
        case SET_ISSUES:
            return action.payload;
        default:
            return state;
    }
}