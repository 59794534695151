import axios from 'axios';
import { HOST_URL } from './host';
export const SET_CATEGORIES = 'SET_CATEGORIES';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
};

function setCategories(categories){
    return {
        type: SET_CATEGORIES,
        payload: categories
    }
}


export function handleGetCategories({token}){
    return async (dispatch) => {
        
       return axios
    .get(`${HOST_URL}/api/user/categories`,{
        headers:{
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        if(res.status === 200){
            dispatch(setCategories(res.data.data))
        }

    })
    .catch((err)=>console.error(err))
    } 
}






