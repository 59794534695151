import axios from 'axios';
import { HOST_URL } from './host';
export const SET_WALLETS = 'SET_WALLETS';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
}
function setWallets(wallets){
    return {
        type: SET_WALLETS,
        payload: wallets
    }
}
export function handleGetWallets({token}){
    return async (dispatch) => {
        
       return axios
    .get(`${HOST_URL}/api/user/wallets`,{
        headers:{
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        if(res.status === 200){
            dispatch(setWallets(res.data.data))
        }

    })
    .catch((err)=>console.error(err))
    } 
}

export async function handleGetWallet({token, id},callback){
    
    return axios
    .get(`${HOST_URL}/api/user/wallet/${id}`,{
        headers:{
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        if(res.status ===200){
            if(res.data.success){
                
                callback(res.data.data)
            }
        }
    })
    .catch((err)=>console.error(err))
}

export async function handleNewWallet({token, title, bank, wallet, service, byDefault},callback){
    
    return axios
    .post(`${HOST_URL}/api/user/wallets?service=${service}`,{
        title,
        bank,
        wallet,
        by_default:byDefault
    },{
        headers:{
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        if(res.status ===200){
            
            if(res.data.success){
                handleGetWallets(token)
                callback({type: 'success', message: res.data.message})
            }else{
                callback({type: 'danger', message: res.data.message})
            }
        }
    })
    .catch((err)=>console.error(err))
}

export async function handleEditWallet({token,id, title, bank, wallet, service, byDefault},callback){
    
    return axios
    .put(`${HOST_URL}/api/user/wallets/${id}?service=${service}`,{
        title,
        bank,
        wallet,
        by_default:byDefault
    },{
        headers:{
            ...headers,
            'Authorization': `Bearer ${token}`
        }
    })
    .then((res)=>{
        if(res.status ===200){
                callback(res.data)
        }
    })
    .catch((err)=>console.error(err))
}