import axios from 'axios';
import { HOST_URL } from './host';
export const RETRIEVE_SUB_ACCOUNTS = 'RETRIEVE_SUB_ACCOUNTS';
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
};
function retrieveSubAccounts(subAccounts){
    return {
        type: RETRIEVE_SUB_ACCOUNTS,
        payload: subAccounts,
    };
}

export function handleRetrieveSubAccounts({token})
{
    return async (dispatch) => {
        
        return axios
    .get(`${HOST_URL}/api/user/subAccounts`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                dispatch(retrieveSubAccounts(res.data.data))
            }
    })
    .catch(err=>console.error(err))
    } 

}

export async function handleGetSubAccount({token, matricule}, callback)
{
        ;
        return axios
    .get(`${HOST_URL}/api/user/get-sopay-user?for=${matricule}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                callback(res.data.data);
            }
    })
    .catch(err=>console.error(err));

}

export async function handleNewSubAccount({token, email, contact},callback){
    
    return axios
    .get(`${HOST_URL}/api/user/new-subAccount?email=${email}&phone=${contact}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`
        },

    })
    .then((res)=>{
        if(res.status === 200){
            if(res.data.success){
                //console.log(res.data)
                callback({type: 'success', message: res.data.message})
            }else{
                callback({type: 'danger', message: res.data.message})
            }
        }
    })
}

export async function handleEditSubAccount({token, email,contact},callback){
    
    return axios
    .get(`${HOST_URL}/api/user/new-subAccount?email=${email}&phone=${contact}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`
        },

    })
    .then((res)=>{
        if(res.status === 200){
            if(res.data.success){
                //console.log(res.data)
                callback({type: 'success', message: res.data.message})
            }else{
                callback({type: 'danger', message: res.data.message})
            }
        }
    })
}