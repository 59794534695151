import axios from "axios";
import { HOST_URL } from "./host";
import { format } from "date-fns";

export const SET_ORDERS = "SET_ORDERS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setOrders(orders) {
  return {
    type: SET_ORDERS,
    payload: orders,
  };
}
export function handleGetOrder({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/order/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetOrders({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/orders`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setOrders(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleStatusOrderAccepted({ token, id, action, delivery_date }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/order/${id}?action=${action}&date=${delivery_date}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleStatusOrderRejected({ token, id, action }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/order/${id}?action=${action}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}
