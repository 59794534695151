import axios from "axios";
import { HOST_URL } from "./host";
export const SET_CHANNELS = "SET_CHANNELS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setChannels(channels) {
  return {
    type: SET_CHANNELS,
    payload: channels,
  };
}
export function handleGetChannel({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/campaign-channel/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetChannelCustomers({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/campaign-channel/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data.customers);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetChannels({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/campaign-channels`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setChannels(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleNewChannel({ token, name, description }, callback) {
  return async () => {
    return axios
      .post(
        `${HOST_URL}/api/user/campaign-channel`,
        {
          name,
          description,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            handleGetChannels(token);
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleUpdateChannel(
  { token, id, name, description },
  callback
) {
  return async () => {
    return axios
      .put(
        `${HOST_URL}/api/user/campaign-channel/${id}`,
        {
          name,
          description,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleStatusChannel({ token, id, active }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/campaign-channel/${id}?active=${active}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDeleteChannel({ token, id }, callback) {
  return async () => {
    return axios
      .delete(`${HOST_URL}/api/user/campaign-channel/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleChannelAddCustomers({ token, customers, id }, callback) {
  return async () => {
    const data = customers.map((customer) => customer.id);
    try {
      const res = await axios.post(
        `${HOST_URL}/api/user/campaign-channel/customer/add`,
        {
          data: data,
          channel: id,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        if (res.data.success) {
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    } catch (error) {
      console.error(error);
      callback({ type: "danger", message: "An error occurred" });
    }
  };
}


export function handleChannelRemoveCustomer({ token, idcustomer, id }, callback) {
  return async () => {
    const data = [idcustomer];
    return axios
      .post(
        `${HOST_URL}/api/user/campaign-channel/customer/remove`,
        {
          data: data,
          channel: id,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            // dispatch(handleGetShop({ token,id }));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}
