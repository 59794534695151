
import { handleConvert } from "./../redux/actions/operation";
export function formattedUser(data){
    return{
        balance: data.balance,
        username: data.username,
        contact: data.contact,
        email: data.email,
        level: data.level,
        is2FA: data.email_auth_enabled,
        apiKey: data.api_key,
        matricule: data.matricule,
        rewards: data.rewards_balance,
        mode: data.signup_mode,
        referer: data.referer,
        currency: data.default_currency.code,
        company: data.company,
        profile: data.profile,
        avatar: data.avatar_path,
        logo: data.company ? data.company.logo : null,
        stamp: data.company ? data.company.stamp : null,
        countRequest: data.nb_trans,
        isApiWithdraw: data.is_api_withdrawal,
        accounts: data.accounts,
        wallets: data.user_wallets,
        isApiDeposit: data.is_api_allow,
        score: data.score,
        head: data.head,
        creditCeil: data.ceiling_credit,
        isUserVerify: data.verif_user,
        setting: data.user_config,
        services: data.user_config.services,
        issues: data.issues,
        virtualCards: data.virtual_cards
    }
}
export function getDifference(date1, date2){
    return date2.getTime() - date1.getTime()
}
export function getTimeDifference(date1, date2){
    return (date2*1000) - date1.getTime()
}

export function makeid(length){
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i+=1 ) {
      result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
   }
   return result;
  }
export function splitArrayInto(array, number){
    const section = Math.ceil(array.length / number) 
    let result = []
    for (let index = 0; index < section; index++) {
        result[index] = array.slice(number * index, (section - (section - (index + 1))) * number)
    }
    return result
}

export function getAmountLimitToTransfert(currency){
    switch (currency) {
        case 'USD':
            return { min: 5, max: 5000};
        case 'XAF':
            return { min: 500, max: 500000};
        case 'EUR':
            return { min: 5, max: 5000};
        case 'BTC':
            return { min: 0.0001, max: 0.5};
        case 'DOGE':
            return { min: 50, max: 100000};
        case 'LTC':
            return { min: 0.01, max: 100};
        case 'ETH':
            return {min: 0.01, max: 10}
        case 'BANK':
            return {min: 25000}
        default:
            return;
    }
}


export function getAmountLimitToTrend(currency){

    switch (currency) {
        case 'USD':
            return { min: 5};
        case 'XAF':
            return { min: 3000 };
        case 'EUR':
            return { min: 5};
        case 'BTC':
             return { min: 5};
        case 'DOGE':
             return { min: 5};
        case 'LTC':
             return { min: 5};
        case 'ETH':
            return { min: 5};
        default:
            return ;
    }
}