import {createStore} from 'redux';
import {persistStore} from 'redux-persist';

import middlewares from './middlewares';
import reducers from './reducers';

const store = createStore(
    reducers, /* preloadedState, */
    middlewares,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
const persistor = persistStore(store)

export { store, persistor }