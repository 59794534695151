import {
    SET_NOTIFICATIONS,
    READ_NOTIFICATION
} from '../actions/notifications';

export default function notifications(state=[], action){
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return action.payload;
        case READ_NOTIFICATION:
            const index = state.findIndex((n)=>n.id===action.payload)
            state[index] =  {
                ...state[index],
                status: 'READ'
            }
            return state
        default:
            return state;
    }
}