import {
    SET_CAMPAIGNS
} from '../actions/campaigns';

export default function campaigns(state=[], action){
    switch (action.type) {
        case SET_CAMPAIGNS:
            return action.payload;
        default:
            return state;
    }
}