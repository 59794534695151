export default{
    translation:{
        shared: {
            'comeback': 'You are back !',
            'welcome': 'Welcome to Soleaspay !',
            'greating_username': 'Hi, {{username}}!',
            'support': 'Invite your friend and earn 500 XAF after their first transaction.',
            'back': 'Back',
            'notifications': 'Notifications',
            'profile': 'Profile',
            'referral': 'Referral',
            'logout': 'Logout',
            'setting': 'Setting',
            'french': 'French',
            'english': 'English',
            'downloadPdf': 'Download PDF',
            'get_started': 'Get Started',
            'landing': 'Home',
            'copy_message': 'Copy',
            'copied_message': 'Copied!',
            'subscription':'My subscription',
        },
        login: {
            'greating': 'Log in to your account.',
            'new': 'Glad to have you with us !',
            'login': 'Log In',
            'forgot_password': 'Forgot Password?',
            'create_new_account': 'Create New Account.',
            'title': 'Log in using your credentials.',
            'remember_me': 'Remember me',
            'login_success': 'Logged in successfully!',
            'login_fail': 'Invalid email or password!'
            },
            form: {
                'merchant': 'Merchant',
                'password': 'Password',
                'email': 'Email',
                'signin': 'Sign In',
                'register': 'Register',
                'login': 'Log In',
                'email_error': 'Invalid email',
                'password_error': 'Password must be greater than 4 characters.',
                'email_required': 'Email required',
                'password_required': 'Password required.',
                'currency': 'Currency',
                'currency_placeholder': 'Choose default currency',
                'new_email': 'New Email',
                'name': 'Name',
                'name_required': 'Name required',
                'country': 'Country',
                'country_required': 'Country required',
                'city': 'City',
                'city_required': 'City required',
                'state': 'State',
                'state_required': 'State required',
                'address': 'Address required',
                'rccm_number': 'RCCM Number ',
                'rccm_number_required': 'RCCM Number required',
                'rccm_issue_date': 'RCCM Registration Date',
                'rccm_issue_date_required': 'RCCM Registration Date required',
                'rccm_issue_place': 'RCCM Registration Place',
                'rccm_issue_place_required': 'RCCM Registration Place required',
                'company_title': 'Company Information',
                'company_button': 'Update Company Information',
                'username': 'Username',
                'username_required': 'Username required',
                'username_error':'Invalid username',
                'user_button': 'Edit My Profile',
                'phone': 'Phone Number',
                'phone_error': 'Invalid phone number',
                'phone_required': 'Phone number required',
                'amount': 'Amount (USD)',
                'amount_required': 'Amount required',
                'amount_invalid': 'Amount must be a number',
                'submit': 'Submit',
                'personnal': 'Personal',
                'professional': 'Professional',
                'signup_title': 'Sign Up',
                'create_account_button': 'Create Account',
                'contact': 'Contact',
                'contact_required': 'Contact required',
                'mode_required': 'Account type required',
                'agree_required': "Your agreement is required",
                "email_invalid": "Invalid email",
                "new_password": "New password",
                "new_password_required": "New password required",
                "confirm_password": "Confirm password",
                "confirm_password_required": "Confirm your password",
                "confirm_password_invalid": "Password must match",
                "instance_invalid_range": "The number of QR codes must be between 1 and 77",
                "reset_password_button": "Reset Password",
                "canceled_action": "Action canceled",
                "shopname": "Shop Name",
                "description": "Description",
                "mode": "Mode",
                "instance": "Number",
                "langue": "Language",
                "select_currency": "Select Currency",
                "select_langue": "Select Language",
                "select_mode": "Select Payment Mode",
                "shopname_required": "Shop name required",
                "shopname_invalid": "Invalid shop name",
                "description_invalid_range": "Description must be less than 50 characters.",
                "mode_payment_required": "Payment mode required",
                "currency_required": "Currency required",
                "langue_required":"Language required",
                "generate_qrcode_button": "Generate payment link",
                "instance_invalid": "Invalid number of QR codes",
                "instance_required": "Number of QR codes required",
                "langue_invalid": "Invalid language",
                "receiver_matricule_required": "Receiver's Matricule Required",
                "send_email_button": "Receive an Email",
                "update_password_button": "Change Password",
                "home" : "Home",
                "pricing": "Our Pricing",
                "pricing_body" : "We apply the lowest commission rate on the market with high system performance and security to improve the user experience and facilitate the development of partner companies.",
                "pricing_rate": "Rate",
                "pricing_start": "From",
                "pricing_payin": "Payin",
                "pricing_withdraw": "Withdrawal",
                "first_name": "Last Name",
                "last_name": "First Name",
                 "civility":'Civility', 
                 "date_of_birth":"Date of birth", 
                 "marital_status":"Marital status", 
                 "profession":"Profession", 
                 "nui":"Identification number", 
                 "identity_doc_type":"Type of document", 
                 "id_issue_date":"Issue date", 
                 "id_issue_place":"Place of issue", 
                 "street":"District", 
                 "personal_information_button":"Edit personal information", 
                 "title_required":"Title required", 
                 "bank_required":"Bank required", 
                 "wallet_required":"Account required", 
                 "contact_invalid":"Invalid contact", 
                 "username_invalid":"Invalid username", 
                 "password_invalid":"Invalid password: (It must contain at least 8 characters with 1 uppercase letter, 1 lowercase letter, 1 digit and 1 special character)", 
                 "password_confirm_invalid":"Incorrect password", 
                 "password_confirm_required":"Confirm password required", 
                 "otp_required":"Code required", 
                 "date_of_birth_required":"Date of birth required", 
                 "street_required":"District required", 
                 "profession_required":"Profession required", 
                 "nui_required":"Identification number required", 
                 "id_issue_date_required":"Issue date required", 
                 "id_issue_place_required":"Place of issue required",
                 'ip_required':'IP address required.',
                 'ip_invalid': 'Invalid IP address',
                 'receiver_name':'Name',
                 'receiver_username':'Username',
                 'receiver_matricule':'Matricule',
                 'receiver_info_title': 'Beneficiary Information',
                 'receiver_search': 'Beneficiary',
                 'receiver_search_desc': 'Enter beneficiary information for the search',
                 'receiver_search_placeholder': 'Search for Beneficiary',
                 'receiver_select': 'Select the Beneficiary',
                 'receiver_not_found': 'User not found',
                 'receiver_search_no_result':'No result for this search !',
                 'name_required': 'Name required',
                 'name_invalid': 'Invalid name',
                 'email_required': 'Email required',
                 'email_invalid': 'Invalid email',
                 'country_required': 'Country required',
                 'country_invalid': 'Invalid country',
                 'products_required': 'Products required',
                 'products_invalid': 'Invalid products',
                 'type_required': 'Type required',
                 'type_invalid': 'Invalid type',
                 'language_required': 'Language required',
                 'language_invalid': 'Invalid language',
                 'currency_required': 'Currency required',
                 'currency_invalid': 'Invalid currency',
                 'note_required': 'Note required',
                 'note_invalid': 'Invalid note',
                 'discount_required': 'Discount required',
                 'discount_invalid': 'Invalid discount',
                 'tax_required': 'Tax required',
                 'tax_invalid': 'Invalid tax',
                 'customer_required': 'Customer required',
                 'customer_invalid': 'Invalid customer',
                 'description_required':'Description required',
                 'price_required':'Price required',
                 'category_required': 'Required category',
                 'category_invalid': 'Invalid category',
                 'reference_required': 'Reference required',
                 'Photo_required': 'Image required',
                 'value_required':'Value required',
                 'message_required':'Message required',
                 'channel_required':'Channel required',
                 'date_required': 'Date required',
                 'template_required':'Template required',
                 'about_required':'About required',
                 'cycle_required':'Cycle required',
                 'code_required':'Code required',
                 'business_phone': 'Business Phone',
                 'business_email': 'Business Email',
                 'registration': 'Registration Number'
            },
            admin: {
                'dashboard': 'Dashboard',
                'search': 'Search',
                'balance': 'Balance',
                'pending': 'Pending',
                'success': 'Payment recieved',
                'witdraw': 'WITHDRAW',
                'deposit': 'Total Transcations',
                'rewards': 'Rewards',
                'referrals': 'My Referrals',
                'requests': 'Requests',
                'trans_list': 'Transaction History',
                'services': 'SoleasPay Services',
                'notifications': 'Notifications',
                'user_profile': 'My Profile',
                'trans_amount': 'Transaction Amount',
                'trans_total': 'Total transactions',
                'trans_recent': 'Recent Transactions',
                'referral_list': 'Referral List',
                'referral_recent': 'Recent Referrals',
                'see_all': 'See All',
                'referral_empty': 'No Referral',
                'trans_empty': 'No Transaction',
                'my_profile': 'My Profile',
                'setting': 'Setting',
                'logout': 'Logout',
                'documentation': 'Documentation',
                'back': 'Back',
                'month': 'Month',
                'day':'Day',
                'trans_history':'History',
                'payment_api': 'Payment API Integration',
                'operations': 'Operations',
                'score' : 'Score',
                'overview':'Overview',
                'user_customers': 'My Customers',
                'user_products': 'My Products',
                'issues': 'My Issues',
                'user_product_new': 'New Product',
                'shops':'e-Business',
                'user_company':'My company',
                'coupon':'Discount coupon',
                'orders':'Orders',
                'user_customer_new': 'New customer',
                'payments': 'Payments',
                'channel': 'My broadcasting channels',
                'marketing': 'Marketing booster',
                'campaign': 'My campaigns',
                'partners':'My partners',
                'import': 'Import list',
            },
            transaction: {
                'reference': 'Reference',
                'operation': 'Operation',
                'status': 'Status',
                'date': 'Date',
                'transaction_amount': 'Transaction Amount (SPC)',
                'receiver': 'Receiver',
                'amount': 'Amount',
                'verify': 'Verify',
                'transaction_detail': 'Transaction Detail',
                'doc_empty': 'No Document',
                'see_detail': 'See Detail',
                'sender': 'Initiator',
                'description': 'Description',
                'wallet':'Wallet',
                'in_service': 'From',
                'in_wallet': 'From Wallet',
                'in_amount': 'Amount Sent',
                'out_service': 'To',
                'out_wallet': 'To Wallet',
                'out_amount': 'Amount Received',
                'payer': 'Payer',
                'service': 'Service',
                'details': 'Transaction Details',
                'new_issue': 'Report an issue'
            },
            profile: {
                'switch_account_button': 'Switch to Professional Account',
                '2fa_button_off': 'Switch to 2FA for more security',
                '2fa_button_on': 'Switch to Default Authentication',
                '2fa_title': 'Authentication',
                '2fa_message_default': 'You are using Default Authentication',
                '2fa_message_activated': 'Two-factor authentication activated',
                'currency_title': 'Default Currency',
                'currency_button': 'Change Currency',
                'change_email_title': 'Change Email',
                'change_email_button': 'Change Email',
                'change_avatar': 'Change Avatar',
                'delete_account_title': 'Delete My Account',
                'delete_account_warning_message': "You're about to delete your account, you'll lose all your data and money. Are you sure you want to do this?",
                "detete_account_warning_confirm": "I really want to delete my account",
                "user_title": "User Information",
                "update_password_title": "Change Password",
                "update_password_button": "Change Password",
                "change_currency_title": "Default Currency",
                "matricule": "Matriculation",
                "level": "Level",
                "referal_link": "Referral Link",
                "goto": "Go to",
                "company_title": "Company Information",
                "account_title": "My Profile",
                "delete_account_warning_confirm": "Yes, I'm sure",
                "change_password_title": "Change My Password",
                "extension_error": "Choose a supported image (PNG/JPEG)",
                "personal_title": "Personal Information",
                "api_title": "API Integration",
                "api_informations_title": "Get the informations for the API",
                "2fa_mode_title": "2FA mode",
                'refresh_apikey':'Are you sure you want to refresh your API_KEY ?'
            },
            operation: {
                'sender_wallet': 'Sender Wallet',
                'receiver_wallet': 'Receiver Wallet',
                'operation_title': '{{operation}} Information',
                'title': 'Transactions',
                'service':'Our Services',
                'service_title': 'Which operation would you like to perform?',
                'transfert_money': 'Send Money',
                'transfert_money_description': 'Send money to anyone on SoleasPay with low fees (~1%).',
                'withdraw_money': 'Withdraw Money',
                'withdraw_money_description': 'Instantly withdraw money from your SoleasPay account to your personal wallet for free.',
                'deposit_money': 'Deposit Money',
                'deposit_money_description': 'Credit your SoleasPay account for free using various payment methods.',
                'withdraw_rewards': 'Withdraw Rewards',
                'withdraw_rewards_description': 'Withdraw your rewards to your SoleasPay balance.',
                'withdraw_fund_title': 'Withdraw funds to a mobile or digital wallet',
                'swap_money': 'E-Change',
                'swap_money_description': 'Transfer money from one of your personal wallets to another.',
                'payment_api_integration': 'Payment API Integration',
                'payment_api_integration_description': 'Integrate our API into your project and receive payments directly into your SoleasPay account.',
                'qr_code_payment': 'Payment Link',
                'qr_code_payment_description': 'Expand your business by receiving payments directly via a short link or QR code generated by our system and maximize your sales.',
                'transfert_funds_title': 'Send Money',
                'withdraw_information': 'Withdrawal Information',
                'receiver_matricule': 'Receiver Matriculation',
                'deposit_fund': 'Deposit to My SoleasPay Account',
                'amount_in_xpi': 'Amount in SPC',
                'qrcode_information': 'Information about the payment link',
                'qrcode_generator_title': 'Payment link Generator',
                'mode_billing': 'Product-Related Invoice',
                'mode_tiping': 'Collection/Receipt',
                'canceled_action': 'Canceled Operation',
                'withdraw_rewards_title': 'Withdraw Rewards Information',
                'out_amount': 'Amount to Receive',
                'withdraw_cash': 'Withdraw Cash',
                'withdraw_cash_description': 'Withdraw money from your SoleasPay account directly in cash, via Express Union agencies or through your bank account.',
                'wallet' : 'Debit Account',
                'description' : 'Transfer Reason',
                'transfert_information' : 'Transfer Information',
                'withdraw_confirmation_title': 'Confirm transaction with the code received in your mailbox.',
                'confirmation_code': 'OTP Code',
                'withdraw_submit_code': "Confirm Withdrawal",
                "sopay_credits": "Microcredit via SoleasPay",
                "sopay_credits_description": "Subscribe to a SoleasPay microcredit based on your activity volume. Our solution is flexible, allowing you to set up your own repayment mode.",
                "sopay_payment": "SoleasPay Payment",
                "sopay_payment_description": "In development",
                'withdraw':'Withdraw',
                'deposit': 'Top Up',
                'confirm_action':'Confirm',
                'cancel_action':'Cancel',
                'mode_tiping': 'Fundraising',
                'select_country':'Services available for:',
            },
            integration: {
                "header": "Payment API Integration",
                "api_access_title": "API ACCESS",
                "activate_key_title": "ACTIVATE API KEY",
                "sopay_button_title": "SOPAY BUTTON INTEGRATION",
                "api_documentation_title": "API DOCUMENTATION",
                "refresh_api": "Refresh API KEY",
                "withdraw_access": "WITHDRAW ACCESS",
                "api_withdraw_msg_warning": "Your API KEY is not activated for withdrawal operations. Please contact support to activate it.",
                "api_withdraw_msg_info": "Your API KEY is activated for withdrawals. Enjoy it :)",
                "deposit_access": "PAYMENT COLLECTION ACCESS",
                "api_deposit_msg_warning": "Your API KEY is not activated for payment collection. Please contact support to activate it.",
                "api_deposit_msg_info": "Your API KEY is activated for payment collection. Enjoy it :)",
                "sopay_button_description": "Sopay Button is easy to integrate, get started with it.",
                "api_documentation_description": "Api documentation is easy to understand.",
                "api_documentation_link": "Read Documentation"
            },
            notification: {
                'title': 'Title',
                'status': 'Status',
                'date': 'Date',
                'read': 'Read',
                'unread': 'Unread',
                'notifications': 'Notifications',
                'content': 'Content',
                'notification_empty': 'No Notification',
                'notification_detail': 'Notification Details'
            },
            auth: {
                'signup_as': 'Sign up as',
                'privacy_policy': 'Privacy Policy',
                'agree_label': 'I agree with the following terms of the ',
                'forgot_password_title': 'Forgot Password',
                'backup_password_email_button': 'Change Password',
                'reset_password_title': 'Reset Your Password',
                'go_to_login': 'Go back to login',
                'activated_account_success':  'Your account has been successfully activated',
                'activation_mail_send': 'Activation mail sent to your mailbox',
                'activation_mail_info': 'Please provide your email to receive an activation mail',
                '2fa_login_title': 'Please enter the 2FA code sent via email to confirm.',
                '2fa_otp': '2FA Code',
                'submit_2fa': 'Submit OTP Code',
                'forgot_password_mail_send': 'Reset password mail sent to your mailbox'
            },
            withdrawFunds: {
                'confirm_action': 'Do you want to withdraw {{amount}} {{currency}} to {{wallet}}?',
                'cash_title': 'Withdraw funds to bank account'
            },
            withdrawRewards: {
                'confirm_action': 'Do you want to withdraw {{amount}} SPC?'
            },
            depositFunds: {
                'confirm_action': 'Do you want to deposit {{amount}} {{currency}} from {{wallet}}?',
            },
            transfertFunds: {
                'confirm_action': 'Do you want to transfer {{amount}} {{currency}} to {{wallet}}?',
            },
            swap: {
                'confirm_action': 'Do you want to swap {{amount}} from your {{currency}} account?',
                'title': 'Transfer money from one wallet to another'
            },
            qrcode: {
                list: {
                    title: 'List of Payment Links',
                    empty: 'Empty',
                    see_detail: 'See Transactions'
                },
                property: {
                    reference: 'Reference',
                    type: 'Type',
                    status: 'Status',
                    link: 'Link',
                    expiredAt: 'Expiration Date',
                    amount: 'Amount'
                },
                action: {
                    new: 'Create a Payment Link',
                },
                details:{
                    title: 'Transaction Details'
                }
            },
        landing:{
            service:{
                'title': 'Our Services',
                'subtitle': 'We offer digital payment solutions in multiple fields',
                'payment': 'Make and receive payments',
                'exchange': 'E-Change',
                'transfert':'Soleaspay Transfer',
                'rewards': 'Earn rewards',
                'internationalPayments': 'International Payments',
                'liveHistory':'Live History',
                'Support':'24/7 Support',
                'integrationApi':'Rest APIs',
                'paymentDescription': 'Whether you are an online store, an online merchant, or a simple consumer, you can instantly send and receive payments on Soleaspay.',
                'exchangeDescription': 'Transfer your money from one of your personal accounts to another quickly with the lowest market exchange rates.',
                'transfertDescription':'Transfer money for free from your account to another Soleaspay account with security settings defined by you (direct or subject to conditions).',
                'rewardsDescription': 'By making transactions on Soleaspay, you can earn rewards for all fee-based operations. You also earn rewards on all transactions from Soleaspay accounts that you have sponsored.',
                'internationalPaymentsDescription': 'Soleaspay allows you to accept international payments through legal channels (Visa, PayPal, etc.) to facilitate the sale of your services online and promote the visibility of your product internationally.',
                'liveHistoryDescription':'Get a real-time history of all transactions made to or from your account.',
                'SupportDescription':'A technical team is available in real-time to guide you step by step 24/7.',
                'integrationApiDescription':'For customized solutions, easily and quickly integrate (once) all payment methods supported by Soleaspay into your project.',
                'qr': 'Soleaspay Qr Code',
                'qrDescription': 'Generate a free QR code to sell or receive instant payment in your Soleaspay account and manage each of them',
                'crypto': 'Buy and Sell Cryptocurrency',
                'cryptoDescription': 'Buy and sell cryptocurrency instantly from your Soleaspay account or using your personal mobile wallet at the best market rate',
                'freeOfCharge' : 'Zero fees',
                'freeOfChargeDescription': 'Account creation, API integration, depositing and withdrawing funds are free on Soleaspay. Even maintaining your account is free.'
            },
        
            calculation:{
                'title': 'Direct Conversion',
                'description': 'Get real-time conversion of supported currencies (USD, EUR, BTC, DOGE, etc...)',
                'currency_calculator':'Current SoleasPay Rate',
                'convert_amount':'Converted Amount',
            },
            stock:{
                'title':'Our Reserves',
                'description': 'Current reserves of Soleaspay for instant transactions'
            },
            downloadApp:{
                title:'Mobile Application Coming Soon',
                description:'Our mobile application offers the possibility to trade directly via local currencies and much more',
            },
            partner:{
                title:'Our Partners',
                subtitle:'Be secure when transacting on SoleasPay'
            },
            slider:{
                item1:'Become a partner and grow your business together',
                item2:'Receive money from your customers anywhere in the world',
                item3:'Receive and send money and cryptocurrency from anywhere, without limit',
                item4:'Integrate digital payment into your online solution'
            },
            faq:{
                head:'Frequently Asked Questions',
                title:'SoleasPay in detail',
                'about_title':'What is Soleaspay?',
                'about_content':'<strong>SoleasPay</strong> is a payment gateway that aggregates nearly 50 means of payment in Cameroon and abroad to allow merchants to receive payments from their customers anywhere in the world.',
                'use_title': 'What is it used for?',
                'use_content': 'Soleaspay is used for: <ul><li>Receiving payments via tools such as qr code or payment links;</li><li>Buying and selling currencies and cryptocurrencies;</li><li>Monetizing websites, e-commerce and/or mobile applications through the integration of the sopay button or Soleaspay payment API;</li><li>Paying for useful services (airtime, camtel, eneo, camwater and canal plus);</li><li>Transferring money between users at low cost.</li></ul>',
                'who_can_use_title':'Who can use Soleaspay?',
                'who_can_use_content':'Soleaspay is designed to be used by development professionals as well as by any user with no knowledge of computer programming. It is suitable for both traditional merchants and e-merchants.',
                'account_type_title':'What are the types of accounts on Soleaspay?',
                'account_type_content':'There are three (03) types of accounts on Soleaspay: <ul><li><strong>Personal account</strong>: Belongs to any Soleaspay user. It allows them to make payments for goods and services, transfer money to another Soleaspay user;</li><li><strong>Merchant account</strong>: Ideal for merchants and e-merchants, it offers in addition to the attributes of the personal account, the possibility to generate and distribute qr codes and payment links to receive money from their customers;</li><li><strong>Professional account</strong>: Essential for companies, it allows them to integrate and consume payment APIs and withdrawal APIs (PayIn and PayOut).</li></ul>',
                'account_create_title':'How to create a Soleaspay account?',
                'account_create_content':'Simply fill out the registration form at: <a href="/auth/register"><em>Sign up here</em></a>',
                'account_activate_title':'How to activate my Soleaspay account?',
                'account_activate_content':'After filling out the account creation form, go to the email box of the email address provided during registration and click on the <em>activate my account</em> button.',
                'account_re_activate_title':'I did not receive an activation email',
                'account_re_activate_content':'If the activation email is not even in your spam folder, go to: <a href="/auth/email-activation">Resend me the activation email</a>',
                'account_kyc_title':'What is KYC on SoleasPay?',
                'account_kyc_content':'At Soleaspay, we place great emphasis on identifying our users, so KYC (Know Your Customer) is the set of techniques we use to keep our information up-to-date on our users.',
                'transactions_title':'What are the different transactions available on Soleaspay?',
                'transactions_content':'The different transactions that can be carried out on Soleaspay are: <ul><li>Receipt of payment</li><li>Payment issuance</li><li>Purchase of communication credit</li><li>Purchase and sale of currencies</li><li>Money transfer between users</li></ul>',
                'transactions_fees_title': 'What does it cost me to use Soleaspay services?',
                'transactions_fees_content': 'At SoleasPay, we employ a consumption-based billing policy, which means you only pay when money leaves sopay for another account. <br> All our rates are available at <a href="/pricing">Our Rates</a>',
                'account_limitation_title': 'Are there any limitations to using my Soleaspay account?',
                'account_limitation_content': 'The use of a Soleaspay account is limited only if its owner has not provided their KYC information. <br> Please contact support for more information related to your account.',
                'account_lifetime_title':'How long can I use my Soleaspay account?',
                'account_lifetime_content':'Once activated, a Soleaspay account is available indefinitely. However, you can delete it at any time in your dashboard.',
                'account_additional_fees_title':'Are there any additional fees for using Soleaspay?',
                'account_additional_fees_content':'No, there are no additional fees for operating a soleaspay account',
                'api_use_title':'How do I integrate Soleaspay into my project?',
                'api_use_content':'You must use one of the two integrations available on soleaspay, namely: <ul><li><strong>The Sopay Button</strong>: a complete widget integrating all our means of payment via a JS script</li><li><strong>The Soleaspay API</strong>: For custom use, integration via our API is ideal.</li></ul>',
                'api_type_title':'What type of API does Soleaspay have?',
                'api_type_content':'The Soleaspay API is a FULL REST API',
                'api_doc_title':'How to integrate Soleaspay API?',
                'api_doc_content':'Simply follow the API documentation available at: <a href="https://doc.mysoleas.com/sopay-api">Soleaspay Doc</a>'
            },
            'language':'LANGUAGE',
            'language_fr': 'French',
            'language_en': 'English'
        },
        
        menu:{
            'accounts': 'My Wallets',
            'external_accounts': 'External Accounts',
            'new_external_account': 'New External Account',
            'user_setting': 'Settings',
            'sub_accounts': 'Sub-Accounts',
            'new_sub_account': 'New Sub-Account',
            'credit_list': 'My Microcredits',
            'new_credit': 'New Microcredit',
            'qrcode_list': 'My Payment Links',
            'whitelist_ip': 'IP Whitelist',
            'whitelist_ip_add': 'Add IP',
            'card_list' : 'My Virtual Visa Cards',
            'issues': 'My Issues',
            'subscription':'My subscription',
            'packages': 'Available Packages'
        },
        balance:{
            title: 'My Accounts',
            name: 'Currency',
            wallet: 'Account',
            amount: 'Balance',
            amount_converted: 'Converted Balance',
            sell: 'Sell'
        },
        trade:{
            'sell_currency': 'Sell Currency',
            'sell_for': 'Exchange For',
            'choose_currency': 'Choose Currency'
        },
        wallet:{
            'list_title': 'External Accounts',
            'action_new': 'New Account',
            'title': 'Title',
            'account': 'Account',
            'bank': 'Bank',
            'service': 'Service',
            'setting': 'Edit',
            'new_description': 'Add Account',
            'wallet': 'Account',
            'select_service' : 'Select a Service',
            'byDefault': 'Default',
            'byDefault_true': 'Yes',
            'byDefault_false': 'No',
            'empty': 'No accounts found.',
            'informations': 'Account Information',
            'status': 'Status',
            'confirm_action':'Are you sure you want to add this external account?'
        },
        
        setting:{
            'create_sub_account': 'Create a Sub-Account',
            'sub_account_action_new': 'New Sub-Account',
            'sub_account_list_title': 'My Sub-Accounts',
            'sub_account_username': 'Username',
            'sub_account_email': 'Email',
            'sub_account_contact': 'Contact',
            'sub_account_balance': 'Balance',
            'sub_account_status': 'Status',
            'sub_account_list_empty': 'No sub-accounts found.',
            'sub_account_details': 'Details',
            'sub_account_edit': 'Edit',
            'sub_accounts_detail': 'Sub-Account Information',
            'sub_account_matricule': 'Matriculation Number',
            'sub_account_last_operation': 'Last Operation at',
            'sub_account_withdraw': 'Withdrawal Allowed',
            'sub_account_transfert': 'Transfer Allowed',
            'sub_account_credit': 'Credit Allowed',
            'sub_account_description': 'Create a Sub-Account',
            'sub_account_title': 'Sub-Account Information',
            'account_title': 'Account Settings',
            'whitelist_ip': 'IP Whitelist',
            'whitelist_title': 'My IP Addresses',
            'whitelist_action_new': 'Add IP Address',
            'whitelist_item_no': 'Item No.',
            'whitelist_status': 'Status',
            'whitelist_enable': 'Enable IP',
            'whitelist_disable': 'Disable IP',
            'whitelist_delete': 'Delete IP',
            'whitelist_empty': 'Whitelist is empty.',
            'kyc_process': 'Verify My Account',
            'account_setting': 'Account Settings',
            'account_setting_list_title': 'My Settings',
            'account_setting_label': 'Setting',
            'account_setting_credit_allow': 'Credit Request',
            'account_setting_withdraw_allow': 'Withdraw from Account',
            'account_setting_transfert_allow': 'Transfer Balance',
            'account_setting_services': 'My Services',
            'account_setting_edit_services': 'Select',
            'account_services': 'My Services',
            'account_service_name': 'Payment Method',
            'account_service_country': 'Country',
            'confirm_new_account' : "Do you really want to create this sub-account ?",
            'account_setting_customer_paid_fees' : 'Fees paid by customer',
            'account_setting_webhook' : 'Notification Link (Whebhook)',
            'account_setting_hash' : 'Notification Hash security',
            'account_setting_send_webhook' : 'Send',
            'company':'My company',
            'confirm_switch_up_action' : 'Migrate Account to Professional ?',
            'switch_up': 'Upgrade Account to Pro',
            'account_setting_campaign_email': 'Email for marketing campaign',
            'account_setting_campaign_label': 'Next campaign label',
            'kyc_complete': 'Company Verified'
        },
        
        credit:{
            'list_title': 'Microcredit List',
            'action_new': 'New Credit',
            'proprety_reference': 'Reference',
            'proprety_link': 'Link',
            'proprety_expiredat': 'Expiration Date',
            'property_type': 'Type',
            'property_status': 'Status',
            'list_empty': 'No credits available at the moment.',
            'new_description': 'Subscribe to a Microcredit',
            'new_title': 'New Credit',
            'max_amount': 'Maximum Amount to Request',
            'amount_in': 'Amount in',
            'return_times': 'Number of Payments',
            'select_times': 'Select Payment Times',
            'one_time': 'One Payment',
            'two_times': 'Two Payments',
            'three_times': 'Three Payments',
            'four_times': 'Four Payments',
            'return_cycle': 'Payment Cycle',
            'select_cycle': 'Select Payment Cycle',
            'cycle_day': 'Daily',
            'cycle_week': 'Weekly',
            'cycle_month': 'Monthly',
            'cycle_two_months': 'Bimonthly',
            'cycle_quarter': 'Quarterly',
            'cycle_semester': 'Semesterly',
            'cycle_year': 'Yearly',
            'description': 'Reason for Credit',
            'step_amount_in': 'Payment Amount in',
            'nextdue': 'Next Payment Due Date',
            'return_amount_in': 'Amount to Return in ',
            'deadline': 'Repayment Deadline',
            'confirm_action': 'Do you agree with the clauses of this loan ?'
        },
        whitelist:{
            'new': 'New IP Address',
            'ip': 'IP Address to Add',
            'confirm_action':'Do you really want to add this IP address ?'
        },
        verify:{
            'verify_title':'Identity Verification',
            'id_photo_label':'ID Card Photo',
            'user_photo_label':'Photo of You Holding the ID Card',
            'document_type_label':'Document Type',
            'document_number_label':'ID Card Number',
            'select_option':'Select Document Type',
            'IDCard':'ID Card',
            'passport':'Passport',
            'driving_license':'Driving License',
            'issue_date_label':'Issue Date',
            'expiry_date_label':'Expiry Date',
            'submit_button':'Submit',
            'localization_photo_label':'Photo of the location map of your home',
            'other_photo_label': 'photo of another supporting document',
            'register':'Photo of the commercial register',
            'office_photo_label':'Photo of office|local',
            'localisation_photo_label': 'Home location file'
            },
        card:{
            'list_title':'My Virtual Visa Cards List',
            'action_new': 'Create a Visa Card',
            'list_empty': 'Empty virtual Card',
            'type': 'Type',
            'number': 'Card Number',
            'status': 'Status',
            'balance': 'Balance',
            'expiredAt': 'Expiration Date',
            'new_description': 'Add a virtual card',
            'firstName': 'Name',
            'lastName': 'Expiry date',
            'amount': 'Amount',
            'currency': 'Currency',
            'select_currency': 'choose a currency',
            'address': 'Address',
            'billingCity': 'City',
            'billingCountry': 'Country',
            'postal_code': 'Postal code',
            'confirm_action': 'Are you sure you want to add my virtual visa card?',
            'usd': 'USD',
            'eur': 'EUR',
            },
        dashboard:{
            'visa_title':'Virtual Visa Card',
            'visa_desc':'Get a Virtual Visa Card for secure online payments.',
            'service_title':'Service Purchase',
            'service_desc':'Easily purchase the services you need through our platform.',
            'swap_title':'Swap',
            'swap_desc':'Transfer money from one of your personal wallets to another.',
            'link_title':'Payment Links',
            'link_desc':'Facilitate your transactions with our payment link generator.',
            'whatsapp_bot_title':'WhatsApp Bot Payment',
            'whatsapp_bot_desc':'Easily make payments via WhatsApp with our payment bot.',
            'API_title':'Payment API and Plugins',
            'API_desc':'Integrate our API and plugins to receive payments directly into your SoleasPay account.',
            'transfert_title':'Money Transfer',
            'transfert_desc':'Send money to anyone on SoleasPay with low fees (~1%).',
            'credit_title':'Micro Loans',
            'credit_desc':'Subscribe to a SoleasPay micro loans based on your account activity',
            'withdraw_cash_title':'Cash Withdrawal',
            'withdraw_cash_desc':'Withdraw money from your SoleasPay account in cash via Express Union or your bank account.',
            'localisation_photo_label':'Photo of the location map of your home',
            'other_photo_label': 'photo of another supporting document',
            'invoice_title' : 'E-Biller',
            'invoice_desc' : 'Create and send invoice to your customers and stay tunned',
            'shop': 'E-Business',
            'shop_desc': 'Easily create and manage your stores, customers and products',
            'marketing_booster': 'Marketing booster',
            'marketing_booster_desc': 'Send marketing emails to promote your products, inform your customers, build customer loyalty.',
            'payment_title' : 'Payment',
            'payment_desc' : 'Collect and Send payments easily'
            },
            dashboard_mobile:{
                'visa_title':'Visa card',
                'service_title':'Purchase Services',
                'swap_title': 'Swap',
                'link_title':'Payment links',
                'API_title': 'API & plugins',
                'transfer_title':'Money transfer',
                'credit_title':'Micro Credit',
                'withdraw_cash_title':'Withdraw cash',
                'other_photo_label': 'Other document',
                'invoice_title': 'E-Invoice',
                'shop': 'E-Business',
                'marketing_booster': 'Marketing booster',
                'payment_title': 'Payment',
              },
            issue:{
              'reference': 'Reference',
              'amount': 'Amount',
              'description': 'Issue description',
              'statut': 'Issue status',
              'list_title': 'My issues',
              'currency': 'Trasaction currency',
              'new': 'New issue',
              'imageissue': 'Attach an image to the request',
            },
            product:{
                'list_title': 'My products',
                'description': 'Brief description of the product',
                'price': 'Price',
                'action_new': 'Create product',
                'action_edit': 'Update product',
                'name': 'Product name',
                'new_description': 'Add new product',
                'detail' : 'Details',
                'edit' : 'Edit',
                'empty':'No product',
                'detail': 'Informations',
                'delete': 'Delete',
                'informations': 'Product information',
                'stasus': 'status',
                'category': 'Product category',
                'select_category': 'Select a category',
                'image1': 'Product photo',
                'image2': 'Another product photo',
                'confirm_action': 'Are you sure you want to add this product?',
                'preview':'Invoice preview',
                'update_product_description':'Edit information',
                'update_success_message':'Information changed!',
                'update_error_message':'Edit failed!',
                'confirm_update':'Are you sure you want to change this information?',
                'discount':'Discount percentage',
                'status':'Status',
                'available':'Available',
                'unavailable':'Unavailable',
                'enable':'Enable',
                'disable':'Disable',
              },
              customer:{
                'name': 'Name',
                'contact': 'Contact',
                'address': 'Address',
                'list_title': 'My customers',
                'action_new': 'New Customer',
                'action_edit': 'Update customer',
                'edit': 'Update',
                'detail': 'Informations',
                'delete': 'Delete',
                'informations': 'Informations about customer',
                'lead' : 'Customer lead',
                'email': 'Email',
                'country': 'Activity Country',
                'city': 'City',
                'empty':'No customer',
                'new_description':'Add a customer',
                'confirm_action': 'Are you sure you want to add this customer?',
                'update_customer_description':'Edit information',
                'import_csv':'Import list',
              },
              invoice:{
                'list_title': 'My invoices',
                'reference': 'Reference',
                'name': 'Customer name',
                'email': 'Customer email',
                'amount': 'Amount',
                'currency': 'Currency',
                'status': 'Status',
                'detail': 'Details',
                'delete': 'Delete',
                'action_new': 'Create an invoice',
                'new_description': 'New invoice',
                'new': 'New invoice',
                'customer': 'Customer',
                'select_customer': 'Select a customer',
                'type': 'Invoice type',
                'select_type': 'Select the invoice type',
                'language': 'Language',
                'select_language': 'Select language',
                'currency': 'Currency',
                'select_currency': 'Select currency',
                'discount': 'Discount',
                'taxable': 'Include taxes',
                'tax': 'Tax percentage',
                'note': 'Footer message',
                'empty': 'No invoice',
                'forExistingCustomer':'A listed customer',
                'forNewCustomer':'An unlisted customer',
                'select_products':'Select products',
                'quantity':'Define the quantity of the selected products',
                'empty_products_quantity': 'No products selected',
                'global_type': 'Global',
                'proforma_type': 'Proforma',
                'deposit_type': 'Deposit',
                'language_fr': 'French',
                'language_en': 'English',
                'xaf': 'XAF',
                'usd': 'USD',
                'eur': 'EUR',
                'confirm_action': 'Are you sure you want to send this invoice to your customer?',
                'for':'Invoice for : ',
                'date':'Date',
                'information': 'Invoice details',
                'add_action':'The invoice has been sent to the customer and recorded',
                'error':'Error creating the invoice',
                'cancel':'Operation canceled',
              },
              shops: {
                'e-shops_title': 'My Business',
                'shops_title': 'My shops',
                'new_shop': 'New shop',
                'shops_desc':'Create and manage your shop',
                'my_products': 'My products',
                'my_products_desc': 'Add your products and consult the list of your products.',
                'my_customers': 'My customers',
                'my_customers_desc': 'Manage your customers by adding them to your list.',
                'coupons': 'Discount coupons',
                'coupons_desc':'Generate discount coupons for your customers.',
                'orders': 'Orders',
                'orders_desc':'Manage orders from your customers.',
                'name':'Shop name',
                'description': 'Brief description of the shop',
                'about': 'About (detailed description)',
                'image':'Shop logo',
                'new_description':'New Shop',
                'confirm_action':'Are you sure you want to create this store',
                'empty':'No shop',
                'shop_enable': 'Enable',
                'shop_disable': 'Disable',
                'delete': 'Delete',
                'modify':'Edit information',
                'products_shop':'Products from the shop',
                'open':'Open',
                'add_product_description':'Add products to your store',
                'list_products_shop':'List of products to add',
                'empty_products': 'No products selected',
                'list_products_title':'List of shop products',
                'add_products':'Add a product',
                'remove':'Remove from list',
                'update_shop_description':'Edit information',
                'update_success_message':'Information changed!',
                'update_error_message':'Edit failed!',
                'confirm_update':'Are you sure you want to change this information?',
                'add_action':'The products have been added to your store',
                'error':'Error adding products',
                'cancel':'Operation canceled',
                'confirm_action_add_products':'Are you sure you want to add these products to your store?',
                'store': 'Classic store',
                'workshop': 'Workshop',
                'cancel':'Operation canceled',
                'type': 'Shop type',
                'select_type':'Select type',
                'customize': 'Customize Shop',
                'config_sidebar': 'Sidebar Position',
                'config_select_sidebar': 'Select One Position',
                'config_sidebar_top': 'TOP',
                'config_sidebar_left': 'LEFT',
                'config_sidebar_right': 'RIGHT',
                'config_sidebar_none': 'None',
                'config_views': 'Products View',
                'config_select_views': 'Select One View',
                'config_views_grid': 'GRID View',
                'config_views_list': 'List View',
                'config_theme': 'Shop Theme',
                'config_select_theme': 'Select one Theme',
                'config_theme_default': 'Default Theme',
                'config_theme_dark': 'Dark Theme',
                'config_theme_other': 'Other Theme',
                'config_items_per_page': 'Number of items per page',

              }, 
              company:{
                'change_logo': 'Change logo',
                'change_stamp': 'Change stamp',
              },
              profilekyc:{
                'confirm_action': 'Are you sure you want to send their information',
              },
              previewInvoice:{
                'description': 'Description',
                'quantity':'Quantity',
                'pu': 'Unit price',
                'total_price': 'Total price',
                'discount':'Discount',
                'total': 'TOTAL',
                'preview_description':'Invoice confirmation',
                'confirm': 'Confirm',
              },
              coupon:{
                'new_description':'Add discount coupon',
                'type': 'Coupon type',
                'select_type':'Select the coupon type',
                'type_percent': 'Percentage',
                'type_fixed': 'Fixed amount',
                'percent_label':'Percentage of discount',
                'fixed_label':'Discount amount',
                'percent_placeholder': 'Percentage',
                'fixed_placeholder': 'Amount',
                'action_new':'Create a coupon',
                'confirm_action':'Are you sure you want to add this coupon?',
                'add_success_message':'Coupon added!',
                'add_error_message':'Error adding the coupon!',
                'for':'Set coupon validity',
                'forLimited':'Limited use',
                'forUnlimited':'Unlimited use',
                'times':'Number of usage',
                'coupon_title':'List of discount coupons',
                'empty': 'No coupon',
                'code': 'Code',
                'coupon_type':'Type',
                'discount_value':'Discount',
                'expired_at':'Expiration date',
                'edit_coupon_description':'Edit information',
                'usage':'Number of usage defined',
                'information': 'Coupon information',
              },
              subscription:{
                'subscription_title':'Choose the best plan for your business',
                'per_month': 'per month.',
                'buy':'Subscribe to this package',
                'previous': 'Subscribe to smaller package',
                'confirm_action': 'Are you sure you want to subscribe to the {{name}} package?',
                'success':'Subscription to the package completed!',
                'error':'Subscription failed.',
                'details_subscription_title':'Current plan details',
                'free':'FREE',
                'noSms': 'No SMS',
                'shop': 'shop',
                'shops': 'shops',
                'products': 'products',
                'coupons': 'Unlimited coupons',
                'noReward': 'No reward',
                'reward':'Rewards (per order)',
                'normalAssist':'Normal assistance',
                'priorityAssist':'Priority Assistance',
                'premiumAssist':'Premium Assistance',
                'realTimeAssist':'Real-time assistance',
                'com_level':'Commission level',
                'noFlyer': 'No flyer',
                'noPromote':'No boost in the month',
                'promote':'boost days per month',
                'monthlyReport':'Monthly activity report',
                'noReport':'No activity report',
                'weeklyReport':'Weekly activity report',
                'autoRenewal':'Automatic renewal',
                'successRenewal':'Renewal of the package changed successfully !',
                'errorRenewal':'An error occurred when changing the renewal of the package!',
                'current' : 'Current package',
                'no_plan_msg':'No active package, subscribe one plz !',
                'select_pack': 'Subscribe here',
                'upgrade_pack': 'Upgrade package',
                'renew': 'Renew subscription',
                'name': 'Package',
                'expired_at': 'Expired At',
                'emails': 'Available mails',
                'sms': 'Available sms',
                'available_coupons': 'Available coupons',
                'subscribe_at': 'Subscribed at'
              },
              order:{
                'confirm_title':'Confirmation',
                'confirm_text':'Are you sure you want to accept this command?',
                'confirm_rejected_text':'Are you sure you want to reject this command?',
                'yes': 'Confirm',
                'no': 'Cancel',
                'accepted_alert':'Order accepted!',
                'rejected_alert':'Order rejected!',
                'error':'An error occurred during the operation!',
                'reference':'Reference',
                'customer_name': 'Customer',
                'amount':'Order amount',
                'status': 'status',
                'list_title':'List of orders',
                'inProgress': 'In Progress',
                'awaiting': 'Waiting',
                'rejected':'Rejected',
                'paid':'Paid',
                'email': 'Customer email',
                'name': 'Customer name',
                'date': 'Order date',
                'contact': 'Customer contact',
                'informations': 'Order informations',
                'products':'Products',
                'shipping': 'Delivery address',
                'payment_type': 'Payment type',
                'at_delivery': 'Payment at delivery',
                'prepayment': 'Prepayment',
                'shipping_amount': 'Delivery amount',
                'empty': 'No order',
                'delivery_date_label': 'Enter the order delivery date',
                'required_date': 'Required delivery date',  
                'date_delivery': 'Delivery date',
              },

              payments:{
                title: 'Payment',
                confirm_action: 'Do you want to collect {{amount}} {{currency}} from {{wallet}} account?',
                collect_title:'Collect a payment',
                mass_title:'Mass payment',
                'emit_title':'Issue a payment',
                'emit_desc':'Facilitate the process of issuing individualized payments with a fast and reliable solution.',
                'mass_desc':'Easily manage mass payments to pay several partners with ease.',
                'collect_desc':'Simplify collecting payments from your customers efficiently and securely.',
                'code':'Payment code',
                'code_instruction':'Click on this button to get the code',
                'requestOtp':'Get code',
                'description': 'Description',
                'otpSentMessage':'The code has been sent to your mailbox !',
                'type':'Type of partner (beneficiary)',
                'choose_amount':'Amount to send',
                'use_defined_amount':'Use the amount defined for each partner',
                'define_new_amount':'Define a new amount to send to everyone',
                'confirm_action_mass': 'Are you sure you want to make this payment?'
              },

              marketing: {
                  "marketing_booster_title": "Marketing Booster",
                  "channel_title": "My Broadcasting Channels",
                  "channel_desc": "Establish your broadcasting channels and define the associated list of customers.",
                  "campaign_title": "My Campaigns",
                  "campaign_desc": "Optimize your marketing strategy with a targeted campaign to attract and retain your customers."
                },

                channel: {
                  "channels_title": "Broadcasting Channels",
                  "new_channel": "New Channel",
                  "customers_channel": "Customers of the Channel",
                  "edit": "Edit",
                  "delete": "Delete",
                  "list_customers_title": "Channel Customers",
                  "empty_customer": "No customers",
                  "add_customers": "Add customer(s)",
                  "add_customers_description": "Add customers to the channel",
                  "list_customer_channel": "List of selected customers",
                  "confirm_action_add_customers": "Do you really want to add these customers to your channel?",
                  "customer_error": "An error occurred during the operation!",
                  "customer_success": "Customer(s) added to the channel!",
                  "new_description": "Add a channel",
                  "name": "Channel Name",
                  "description": "Channel Description",
                  "confirm_action": "Do you really want to add this channel?",
                  "add_success_message": "Channel added!",
                  "add_error_message": "An error occurred during the operation!",
                  "delete": "Delete",
                  'remove': 'Remove',
                  'update_channel_description':'Edit information',
                },
                campaign:{
                    'new_description': 'New campaign',
                    'name': 'Campaign name',
                    'type': 'Campaign type',
                    'template': 'Template',
                    'message': 'Campaign message',
                    'start_date': 'Start date and time',
                    'channel': 'Broadcast channel',
                    'select_channel': 'Select channel',
                    'select_type': 'Select type',
                    'select_template': 'Select template',
                    'email': 'Email',
                    'sms': 'SMS',
                    'soleaspay': 'Soleaspay',
                    'list_title': 'List of campaigns',
                    'empty': 'No campaign',
                    'action_new': 'New campaign',
                    'confirm_action': 'Are you sure you want to create this campaign?',
                    'add_error_message': 'An error occurred during the operation!',
                    'add_success_message': 'Campaign created!',
                    'status': 'Status',
                    'edit': 'Modifier',
                    'delete': 'Delete',
                    'detail': 'Information',
                    'pending': 'In progress',
                    'done': 'Done',
                    'update_description':'Edit information',
                    'date': 'Creation date',
                    'informations': 'Campaign informations',
                    'update_campaign_description':'Edit information',
                    'default': 'Default',
                  },
                  partner:{
                    'name': 'Partner name',
                    'email': 'Partner email',
                    'contact': 'Partner contact',
                    'type': 'Partner type',
                    'pay_amount':'Amount to pay',
                    'cycle':'Payment cycle',
                    'employee': 'Employee',
                    'supplier':'Supplier',
                    'other': 'Other',
                    'month':'Month',
                    'year':'Year',
                    'quarter': 'Quarter',
                    'semester': 'Semester',
                    'new_description':'Add a partner',
                    'list_title':'List of partners',
                    'empty': 'No partners',
                    'action_new':'Add partner',
                    'partners_title':'My partners',
                    'partners_desc':'List your partners',
                    'confirm_action':'Are you sure you want to add this partner?',
                    'select_type':'Choose the type',
                    'select_cycle':'Choose the cycle',
                    'edit': 'Edit',
                    'detail':'Information',
                    'information': 'Partner information',
                    'update_partner_description':'Edit partner information',
                    'import_csv':'Import list',
                  },
                  virtualCard:{
                    'name': 'customer name',
                    'company': 'Company name',
                    'surname': 'customer surname',
                    'email': 'customer email',
                    'dob': 'Date of Birth',
                    'cni': 'ID Card Number',
                    'phone':'Phone Number',
                    'region':'Region',
                    'city': 'City',
                    'address':'Address',
                    'postal_code': 'Postal Code',
            
                  },
                  CardCustomer:{
                    'name': 'My virtual cards',
                    'cos': 'Customers cards',
                    'personal': 'Person',
                    'enterprise': 'Enterprise',
                  },
                  CardVisa:{
                    'name': 'My virtual cards',
                    'cos': 'Customers cards',
                    'personal': 'Basic cards',
                    'enterprise': 'Premium cards',
                    'title': 'Create a card',
                    'grad': 'Select a Grade :',
                    'grad1': 'Basic',
                    'grad2': 'Premium',
                    'visa': 'Visa',
                    'act': 'Back',
                    'confirm': 'Transaction Summary',
                    'mas': 'Mastercard',
                    'titl1': 'Type of card :',
                    'ty1': 'Personal usage',
                    'frais': 'Creation fees: 3 USD',
                    'til': 'Usage Type',
                    'ty2': ' professional usage',
                    'titl': 'Select a type',
                    'card': 'New customer',
                    'list_title': 'List of customers',
                    'list_title1': 'List of my virtuelle card',
                    'solde': 'Balance:',
                    'cvv': 'CVV',
                    'exp': 'EXPIRES',
                    'tuti': 'CARD HOLDER',
                    'create': 'Create virtual card',
                    
                    
                  },
                  vir:{
                    'fn': 'First Name',
                    'ln': 'Last Name',
                    'dob': 'Date of birth',
                    'address': 'Address',
                    'city': 'City',
                  },
                  error:{
                    'grade': 'First Name',
                    'ln': 'Last Name',
                    'dob': 'Date of birth',
                    'enterprise': 'Enterprise',
                  },
                  creation:{
                    'type': 'Virtual card creation ',
                    'cat': 'Category of card :',
                    'amount': 'The first amount to be recharge :',
                    'fee': 'Creation fees :',
                    'but': 'Did you really want to create a virtual card',
                    'cancel_action': 'Cancel',
                    'confirm_action': 'Confirm',
                    'tst': 'Amount to be pay : ',
                    'tst1': 'No customers',
                    
                   
                  },
                  import:{
                    'import_partner_title':'Import partner list',
                    'import_customer_title':'Import customer list',
                    'ensure_correct_model':'Please ensure you are using the correct file model. Any other model will not be supported. To download the model, please click the "Download" button on the top right.',
                    'download': 'Download',
                    'choose':'Choose the file to import',
                    'upload_success': 'List uploaded successfully!',
                    'upload_failed': 'The import failed!',
                    'file_required': 'File to import required',
                  }
                  
            
              
              
    }
}