import {
    RETRIEVE_WHITELIST,
} from '../actions/whitelist';

const initialState = []
export default function whitelist(state=initialState, action){
    switch (action.type) {
        case RETRIEVE_WHITELIST:
            return action.payload;
        default:
            return state;
    }
}