import axios from "axios";
import { HOST_URL } from "./host";
export const SET_SHOPS = "SET_SHOPS";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};

function setShops(shops) {
  return {
    type: SET_SHOPS,
    payload: shops,
  };
}
export function handleGetShop({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/shop/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetShopProducts({ token, id }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/shop/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data.products);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetShops({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/shops`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setShops(res.data.data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export async function handleNewShop(
  { token, name, description, data, about, type },
  callback
) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("about", about);
    formData.append("type", type);
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    resolve(formData);
  })
    .then((formData) => {
      return axios.post(`${HOST_URL}/api/user/shop`, formData, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          handleGetShops(token);
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    })
    .catch((err) => console.error(err));
}



export function handleUpdateShop({ token, id, name,description, about, type }, callback) {
  return async () => {
    return axios
    .put(`${HOST_URL}/api/user/shop/${id}`,
        {
          name,
          description,
          about,
          type,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleCustomizeShop({ token, id, sidebar,views, theme, itemsPerPage }, callback) {
  return async () => {
    return axios
    .put(`${HOST_URL}/api/user/shop-config/${id}`,
        {
          sidebar,
          views,
          theme,
          items_per_page: itemsPerPage,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}


export function handleStatusShop({ token, id, active }, callback) {
  return async () => {
    return axios
      .get(`${HOST_URL}/api/user/shop/${id}?active=${active}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDeleteShop({ token, id }, callback) {
  return async () => {
    return axios
      .delete(`${HOST_URL}/api/user/shop/${id}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleChangeLogo({ token, file, origin, id }, callback) {
  const formData = new FormData();
  formData.append("logo", file);

  return async () => {
    return axios
      .post(
        `${HOST_URL}/api/user/company/update-logo?for=${origin}&id=${id}`,
        formData,
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            console.log(res.data.data);
            callback(res.data.message);
          } else {
            callback(res.data.message);
          }
        } else {
          //console.log(res)
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleShopAddProducts({ token, products, id }, callback) {
  return async () => {
    const data = products.map((product) => product.id);
    return axios
      .post(
        `${HOST_URL}/api/user/shop/products/add`,
        {
          data: data,
          shop: id,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            // dispatch(handleGetShop({ token,id }));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleShopRemoveProducts({ token, idproduct, id }, callback) {
  return async () => {
    const data = [idproduct];
    return axios
      .post(
        `${HOST_URL}/api/user/shop/products/remove`,
        {
          data: data,
          shop: id,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            // dispatch(handleGetShop({ token,id }));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}
